.projects{
    padding-bottom: 0rem;
}
.projects__container {
    width: 100%;
    max-width: 900px; /* Adjust this value as needed */
    margin: 0 auto; /* Centers the container */
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    /* column-gap: 4rem; */ /* Remove or comment out this line */
}

.projects__img{
    border-radius: 1.5rem;
    justify-self: center;
}

.projects_info{
    grid-template-columns: repeate(3, 140px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.projects__box{
    background-color: var(--container-bolor);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    /* text-align: left; */
    padding: 2rem 1.25rem;
}

.section__title{
    color: rgb(54, 41, 81);
    font-size: var(--h1-font-size);
    text-align: center;
    padding-bottom: 0.5rem;
}

.projects__title{
    font-size: var(--h2-font-size);
    font-weight: var(--font-bold);
}
.projects__topic{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* font-family: 'Merriweather', serif; */
}
.projects__date{
    margin-left: auto;
    font-size: var(--h4-font-size);
    font-family: 'Merriweather', serif;
    color: rgb(142, 114, 203);
}
.projects__subtitle{
    color: rgb(73, 12, 205);
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    font-family: 'Merriweather', serif;
}
.projects__subtitle:hover{
    color: rgb(101, 75, 152);
    font-weight: 510;
}

.projects__subheading{
    font-size: var(--h4-font-size);
    font-weight: var(--font-bold);
    padding-top: 0.5rem;
    font-family: 'Merriweather', serif;
}
.projects__description{
    padding: 0 0 0 0;
    /* margin-bottom: var(--mb-2-5); */
    font-size: var(--h4-font-size);
    font-family: 'Merriweather', serif;
    color: black;
}


/* ========== BREAKPOINTS ========== */
/* For large devices */
@media screen and (max-width: 1092px){
    .projects__container{
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }
    .projects__box{
        padding: 0.75rem 0.5rem;
    }
    .projects__info{
        justify-content: center;
    }
    .projects__description{
        padding: 0 5rem;
        margin-bottom: 2rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px){
    .projects__info{
        grid-template-columns: 1fr;
    }
    .projects__description{
        padding: 0;
    }
}

@media screen and (max-width: 576px){
}

/* For small devices */
@media screen and (max-width: 350px){
    .projects__info{
        grid-template-columns: repeat(2, 1fr);
    }
}